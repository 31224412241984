<template>
  <div class="setBankCard">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title">设置银行卡</span>
      </div>
      <el-form ref="form" :model="form" label-width="150px" :rules="rules">
        <el-form-item label="开户名称：" prop="accountName">
          <el-input v-model="form.accountName" placeholder="请输入开户名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="开户银行：" prop="bankName">
          <el-input v-model="form.bankName" placeholder="请输入开户银行" clearable></el-input>
        </el-form-item>
        <el-form-item label="开户行支行：" prop="bankBranch">
          <el-input v-model="form.bankBranch" placeholder="请输入开户行支行" clearable></el-input>
        </el-form-item>
        <el-form-item label="开户卡号：" prop="bankNumber">
          <el-input
            v-model="form.bankNumber"
            placeholder="请输入开户卡号"
            oninput="value=value.replace(/[^\d]/g,'')"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="预留手机号：">
          <span>{{ superAdminDetail.superPhone }}</span>
          <el-button
            type="primary"
            size="medium"
            class="codeBtn"
            :class="{ gray: count > 0 }"
            :disabled="count > 0"
            @click="getCode"
            >{{ count > 0 ? count + 's' : '获取验证码' }}</el-button
          >
        </el-form-item>
        <el-form-item label="短信验证码：" prop="code">
          <el-input v-model="form.code" class="input-width" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" class="submit" @click="handleSubmit('form')"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>

    <el-dialog
      title="确定绑定"
      :visible.sync="dialogFormVisible"
      width="30%"
      class="dialog"
      :show-close="false"
    >
      <div>您确定将银行卡绑定到您的学堂，学堂和平台所有交易行为将全部以该银行卡为准</div>
      <div>请确定绑定银行卡的信息：</div>
      <div>开户名称：{{ form.accountName }}</div>
      <div>开户行名称：{{ form.bankName }}</div>
      <div>开户行号：{{ form.bankNumber }}</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import to from 'await-to'
import { getOrderBank, getAccountsCode, getSearchSuperAdmin } from '@/api/college'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      form: {
        accountName: '',
        bankName: '',
        bankBranch: '',
        bankNumber: '',
        code: '',
      },
      rules: {
        accountName: [{ required: true, message: '请输入开户名称', trigger: 'blur' }],
        bankBranch: [{ required: true, message: '请输入开户行支行', trigger: 'blur' }],
        bankName: [{ required: true, message: '请输入开户行支行', trigger: 'blur' }],
        bankNumber: [{ required: true, message: '请输入开户卡号', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
      },
      dialogFormVisible: false,
      count: '',
      superAdminDetail: {},
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  created() {
    this.getSearchSuperAdminData()
  },
  methods: {
    async getAccountsCodeData() {
      const [, err] = await to(getAccountsCode())
      if (err) return this.$message({ type: 'error', message: err.msg })
    },
    async getSearchSuperAdminData() {
      const [res, err] = await to(getSearchSuperAdmin())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.superAdminDetail = res.data
    },
    getCode() {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
        this.getAccountsCodeData()
      }
    },
    async submit() {
      const [, err] = await to(getOrderBank(this.form))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.$message({ type: 'success', message: '设置成功' })
      this.$refs.form.resetFields()
      this.dialogFormVisible = false
      this.$router.push('accountsManage')
    },
    cancel() {
      this.dialogFormVisible = false
      this.$refs.form.resetFields()
    },
    async handleSubmit(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.dialogFormVisible = true
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.setBankCard {
  background-color: #fff;
  ::v-deep .el-tabs__nav {
    width: 100%;
  }
  ::v-deep .el-tabs__item {
    width: 50%;
    text-align: center;
    font-size: 18px;
  }
  ::v-deep .el-tabs__active-bar {
    width: 15% !important;
    left: 17%;
  }
  .codeBtn {
    margin-left: 22px;
  }
  .gray {
    background-color: #909399;
  }
  .submit {
    margin-top: 30px;
  }
  .dialog {
    div {
      margin: 15px 0;
    }
  }
}
</style>
